import { MainLayout } from '@/layout'
import { Seo } from '@/shared'
import { InfoPageLayout } from 'views/Info'
import Overview from 'views/Info/Overview'

const InfoPage = () => {
  return (
    <MainLayout title="despace overview">
    <Seo
      title="DeSpace | Info"
      description="despace deswap aggregator dex"
      keywords="swap, dex, liquidity, despace, deswap, aggregator, dex"
    />
    <Overview />
  </MainLayout>)
}


InfoPage.Layout = InfoPageLayout

export default InfoPage
